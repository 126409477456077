import { dataToURLString, optionToRequest, } from 'aspida';
import axios from 'axios';
export default (client = axios, config) => ({
    baseURL: (config === null || config === void 0 ? void 0 : config.baseURL) || client.defaults.baseURL,
    fetch(baseURL, url, method, params, type) {
        const send = (responseType) => async () => {
            var _a;
            const request = optionToRequest(params, type);
            const res = await client.request({
                paramsSerializer: params => dataToURLString(params),
                ...config,
                url,
                baseURL,
                method,
                responseType,
                ...request === null || request === void 0 ? void 0 : request.config,
                data: request === null || request === void 0 ? void 0 : request.httpBody,
                params: request === null || request === void 0 ? void 0 : request.query,
                headers: {
                    ...config === null || config === void 0 ? void 0 : config.headers,
                    ...(_a = request === null || request === void 0 ? void 0 : request.config) === null || _a === void 0 ? void 0 : _a.headers,
                    ...request === null || request === void 0 ? void 0 : request.headers,
                },
            });
            const { status, headers, data } = res;
            return { status, headers, body: data, originalResponse: res };
        };
        return {
            send: send(),
            json: send('json'),
            text: send('text'),
            arrayBuffer: send('arraybuffer'),
            blob: send('blob'),
            formData: send(),
        };
    },
});
