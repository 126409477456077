import React from "react";

import {
  Grid
} from "@mui/material";
import { setTitle } from "#/components/lib/jotai";
const AreaMap: React.VFC = () => {
  setTitle("エリアマップ");
  return (
    <>
      <Grid container spacing={2}>
      </Grid>
    </>
  );

}
export default AreaMap;