import NodeFormData from 'form-data';
export const headersToObject = (headers) => [...headers.entries()].reduce((prev, [key, val]) => ({ ...prev, [key]: val }), {});
const appendDataToFormData = (data, formData) => {
    for (const key in data) {
        if (Array.isArray(data[key])) {
            data[key].forEach((d) => formData.append(key, d));
        }
        else if (data[key] != null) {
            formData.append(key, data[key]);
        }
    }
    return formData;
};
const encode = (str) => encodeURIComponent(str).replace(/[!'()~]|%20|%00/g, match => ({
    '!': '%21',
    "'": '%27',
    '(': '%28',
    ')': '%29',
    '~': '%7E',
    '%20': '+',
    '%00': '\x00',
}[match]));
export const dataToURLString = (data) => Object.keys(data)
    .filter(key => data[key] != null)
    .map(key => Array.isArray(data[key])
    ? data[key].map((v) => `${encode(key)}=${encode(v)}`).join('&')
    : `${encode(key)}=${encode(data[key])}`)
    .join('&');
const hasFormData = typeof FormData !== 'undefined';
export const optionToRequest = (option, type) => {
    if ((option === null || option === void 0 ? void 0 : option.body) === undefined)
        return option;
    let httpBody;
    let headers = {};
    switch (type) {
        case 'FormData':
            if (hasFormData) {
                httpBody = appendDataToFormData(option.body, new FormData());
            }
            else {
                const formData = new NodeFormData();
                httpBody = appendDataToFormData(option.body, formData);
                headers = formData.getHeaders();
            }
            break;
        case 'URLSearchParams':
            httpBody = dataToURLString(option.body);
            headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
            break;
        case 'ArrayBuffer':
        case 'string':
        case 'Blob':
        case 'any':
            httpBody = option.body;
            break;
        default:
            httpBody = JSON.stringify(option.body);
            headers['Content-Type'] = 'application/json;charset=utf-8';
            break;
    }
    return { httpBody, ...option, headers: { ...headers, ...option.headers } };
};
