import React, { useState } from "react";
import { useAtomValue } from "jotai";
import { tokenAtom, profileAtom, setTitle } from "#/components/lib/jotai";
import apiClient from "#/axios-config";

import {
  Grid,
  TextField,
  Box,
  Button,
  CircularProgress,
  FormControl, InputLabel, Select, MenuItem,
} from "@mui/material";

import {
  handleApiError,
} from "#/components/lib/commonFunction";

const RegistUser: React.VFC = () => {
  setTitle("ユーザー登録");
  const token = useAtomValue(tokenAtom);
  const profile = useAtomValue(profileAtom);
  const [userId, setUserId] = useState<string>("");
  const [displayName, setDisplayName ] = useState<string>("")
  const [userType, setUserType ] = useState<string>("")
  const [guestType, setGuestType ] = useState<string>("")
  const [passPhrase, setPassphrase] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const addUser = () => {
    if (token && profile && !loading) {
      setLoading(true);
      apiClient(process.env.REACT_APP_API_BASE_URL)
        .auth.register.$post({
        body: {
          user_id: userId,
          display_name: displayName,
          user_type: userType,
          guest_type: guestType,
          available: 1,
          passphrase: passPhrase,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(() => {
          console.log("success")
        })
        .catch((err) => {
          handleApiError(err, "user_register_post");
        });
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ width: "min(100%, 300px)" }}>
          <TextField
            id="userID"
            label="ユーザーID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            required
            id="displayName"
            label="表示名"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            margin="normal"
            fullWidth
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="userType-label">ユーザー種類</InputLabel>
            <Select
              labelId="userType"
              id="userType"
              value={userType}
              label="ユーザー種類"
              onChange={(e) => setUserType(e.target.value)}
            >
              <MenuItem value={"exhibit"}>展示用アカウント</MenuItem>
              <MenuItem value={"moderator"}>管理用アカウント</MenuItem>
              <MenuItem value={"executive"}>文実用アカウント</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="guestType-label">ゲストの種類</InputLabel>
            <Select
              labelId="guestType"
              id="guestType"
              value={guestType}
              label="ゲストの種類"
              onChange={(e) => setGuestType(e.target.value)}
            >
              <MenuItem value={"student"}>生徒</MenuItem>
              <MenuItem value={"teacher"}>教員</MenuItem>
              <MenuItem value={"other"}>その他</MenuItem>
            </Select>
          </FormControl>
          <TextField
            required
            id="passPhrase"
            label="パスワード"
            value={passPhrase}
            onChange={(e) => setPassphrase(e.target.value)}
            margin="normal"
            fullWidth
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                addUser();
              }
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "1rem",
            }}
          >
            {loading && <CircularProgress size={25} thickness={6} />}
            <Button
              onClick={addUser}
              disabled={loading}
              variant="contained"
            >
              登録
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RegistUser;
