import React, { useState } from "react";
import { useAtomValue } from "jotai";
import { tokenAtom, profileAtom, setTitle } from "#/components/lib/jotai";
import apiClient from "#/axios-config";

import {
  Grid,
  TextField,
  Box,
  Button,
  CircularProgress,
  FormControl, InputLabel, Select, MenuItem,
} from "@mui/material";

import {
  handleApiError,
} from "#/components/lib/commonFunction";
import {AxiosError} from "axios";

const RegistRoom: React.VFC = () => {
  setTitle("展示企画登録");
  const token = useAtomValue(tokenAtom);
  const profile = useAtomValue(profileAtom);
  const [roomName, setRoomName] = useState<string>("");
  const [exhibitName, setExhibitName ] = useState<string>("")
  const [groupName, setGroupName ] = useState<string>("")
  const [ownerId, setOwnerId ] = useState<string>("")
  const [exhibitType, setExhibitType ] = useState<string>("")
  const [capacity, setCapacity ] = useState<number>(20)
  const [loading, setLoading] = useState<boolean>(false);

  const addRoom = () => {
    if (token && profile && !loading) {
      setLoading(true);
      apiClient(process.env.REACT_APP_API_BASE_URL)
        .exhibit.list.$get({
          headers: {
              Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res)
          const rawId = String(Number(res[res.length - 1].exhibit_id) + 1)
          const exhibitId = ("0".repeat(10 - rawId.length)) + rawId
          console.log(exhibitId)
          apiClient(process.env.REACT_APP_API_BASE_URL)
            .exhibit.register.$post({
            body: {
              exhibit_id: exhibitId,
              exhibit_name: exhibitName,
              group_name: groupName,
              room_name: roomName,
              owner_id: ownerId,
              exhibit_type: exhibitType,
              status: 1,
              capacity: capacity,
            },
            headers: { Authorization: `Bearer ${token}` },
          })
            .then(() => {
              console.log("success")
            })
            .catch((err) => {
              handleApiError(err, "guest_register_post");
            });
          setLoading(false);
        })
        .catch((err: AxiosError) => {
          handleApiError(err, "exhibit_list_get");
        });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ width: "min(100%, 300px)" }}>
          <TextField
            id="roomName"
            label="場所名(教室名)"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            id="exhibitName"
            label="企画名"
            value={exhibitName}
            onChange={(e) => setExhibitName(e.target.value)}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            id="groupName"
            label="団体名"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            id="ownerId"
            label="代表者名"
            value={ownerId}
            onChange={(e) => setOwnerId(e.target.value)}
            margin="normal"
            fullWidth
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="exhibitType-label">場所の種類</InputLabel>
            <Select
              labelId="exhibitType"
              id="exhibitType"
              value={exhibitType}
              label="場所の種類"
              onChange={(e) => setExhibitType(e.target.value)}
            >
              <MenuItem value={"class"}>教室</MenuItem>
              <MenuItem value={"club"}>部活動</MenuItem>
              <MenuItem value={"stage"}>ステージ</MenuItem>
              <MenuItem value={"other"}>その他</MenuItem>
            </Select>
          </FormControl>
          <TextField
              id="capacity"
              label="最大許容人数"
              value={capacity}
              onChange={(e) => setCapacity(Number(e.target.value))}
              margin="normal"
              fullWidth
              required
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  addRoom();
                }
              }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "1rem",
            }}
          >
            {loading && <CircularProgress size={25} thickness={6} />}
            <Button
              onClick={addRoom}
              disabled={loading}
              variant="contained"
            >
              登録
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RegistRoom;
