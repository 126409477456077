import React from "react";
import { useSearchParams } from "react-router-dom";

import {
  Alert,
  Box,
  Card,
  Grid, List, ListItem, ListItemIcon, ListItemText, Typography
} from "@mui/material";
import { setTitle } from "#/components/lib/jotai";
import { guestIdValidation } from "#/components/lib/commonFunction";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import QRCode from "react-qr-code";
import useDeviceWidth from "#/components/lib/useDeviceWidth";

const QrPage: React.VFC = () => {
  setTitle("QRコード");
  const { largerThanMD } = useDeviceWidth();
  const [searchParams] = useSearchParams();

  const guestId = searchParams.get("id");
  const guestHash = searchParams.get("token")
  const guestType = searchParams.get("type")

  return (
    <>
      {guestId && guestIdValidation(guestId) ? (
        <Grid item xs={12} md={6}>
          <Card variant="outlined" sx={{ p: 2 }}>
            <Typography variant="h3">ゲスト情報</Typography>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <PersonRoundedIcon />
                </ListItemIcon>
                <ListItemText>{guestId}</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PeopleRoundedIcon />
                </ListItemIcon>
                <ListItemText>
                  {guestType === "family"
                    ? "保護者"
                    : guestType === "student"
                      ? "生徒"
                      : guestType === "teacher"
                        ? "教員"
                        : "その他"}
                </ListItemText>
              </ListItem>
            </List>
          </Card>
          <Card variant="outlined" sx={{ p: 2, mt: 2 }}>
            <Typography variant="h3">あなたのQRコード</Typography>
            <Box sx={{ textAlign: "center", m: 2 }}>
              <QRCode value={guestId} level="H" />
            </Box>
          </Card>
        </Grid>
      ): (
        <Grid container spacing={2}>
          <Alert
            severity="error"
            variant="filled"
            sx={{ my: 1, mx: !largerThanMD ? 1 : 0 }}
          >
            このゲストIDは無効です。
          </Alert>

          <p>Guest ID = {guestId}</p>
          <p>Guest Hash = {guestHash}</p>
          <p>Guest Type = {guestType}</p>
        </Grid>
  )}
    </>
  );
}
export default QrPage;