import React from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

import {
  Card, CardActionArea, CardContent,
  Grid, Typography
} from "@mui/material";
import { setTitle } from "#/components/lib/jotai";

const GuestIndex: React.VFC = () => {
  setTitle('ゲストページ')
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const guestId = searchParams.get("id") || "";
  const guestHash = searchParams.get("token") || "";
  const guestType = searchParams.get("type") || "";

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Card variant="outlined" sx={{ height: "100%" }}>
            <CardActionArea
              onClick={() =>
                navigate(`/guest/qr?id=${guestId}&token=${guestHash}&type=${guestType}`)
              }
              sx={{ height: "100%" }}
            >
              <CardContent sx={{ p: 2, height: "100%" }}>
                <Typography variant="h3">QRコード</Typography>
                <Typography variant="body1" sx={{ p: 1 }}>
                  あなたの入退場用のQRコードを表示します。
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Card variant="outlined" sx={{ height: "100%" }}>
            <CardActionArea
              onClick={() => navigate(`/guest/schedule?id=${guestId}&token=${guestHash}&type=${guestType}`)}
              sx={{ height: "100%" }}
            >
              <CardContent sx={{ p: 2, height: "100%" }}>
                <Typography variant="h3">スケジュール</Typography>
                <Typography variant="body1" sx={{ p: 1 }}>
                  ステージ等のスケジュール情報を表示します。
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Card variant="outlined" sx={{ height: "100%" }}>
            <CardActionArea
              onClick={() => navigate(`/guest/map?id=${guestId}&token=${guestHash}&type=${guestType}`)}
              sx={{ height: "100%" }}
            >
              <CardContent sx={{ p: 2, height: "100%" }}>
                <Typography variant="h3">エリアマップ</Typography>
                <Typography variant="body1" sx={{ p: 1 }}>
                  エリアマップを表示します。
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </>
  );

}
export default GuestIndex;
