import React, { Suspense } from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

import {
  Drawer,
  Box,
  Toolbar,
  List,
  Divider,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ListItemButton, {
  ListItemButtonProps,
} from "@mui/material/ListItemButton";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import QrCodeIcon from '@mui/icons-material/QrCode';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MapIcon from '@mui/icons-material/Map';

import theme from "#/components/lib/theme";
import UserInfo from "#/components/block/UserInfo";
import Version from "#/components/block/Version";

const drawerWidth = localStorage.getItem("viewOnly") === "yes" ? 0 : 250;

const GuestDrawerLeft: React.VFC = () => {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const guestId = searchParams.get("id") || "";
  const guestHash = searchParams.get("token") || "";
  const guestType = searchParams.get("type") || "";

  const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps>(
    () => ({
      margin: ".1rem .5rem",
      borderRadius: theme.shape.borderRadius,
    })
  );

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
        zIndex: 100,
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar>
        <Typography
          variant="h1"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/", { replace: true })}
        >
          KHSCFS
        </Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Suspense fallback={<p>hey</p>}>
          <UserInfo />
        </Suspense>
      </Box>
      <Divider />
        <List>
          <StyledListItemButton
            selected={path === "/guest" || path.startsWith("/guest?")}
            onClick={() => navigate(`/guest?id=${guestId}&token=${guestHash}&type=${guestType}`)}
          >
            <ListItemIcon>
              <HomeRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="ホーム" />
          </StyledListItemButton>
          <StyledListItemButton
            selected={path.startsWith("/guest/qr")}
            onClick={() => navigate(`/guest/qr?id=${guestId}&token=${guestHash}&type=${guestType}`)}
          >
            <ListItemIcon>
              <QrCodeIcon />
            </ListItemIcon>
            <ListItemText primary="QRコード" />
          </StyledListItemButton>
          <StyledListItemButton
            selected={path.startsWith("/guest/schedule")}
            onClick={() => navigate(`/guest/schedule?id=${guestId}&token=${guestHash}&type=${guestType}`)}
          >
            <ListItemIcon>
              <CalendarMonthIcon />
            </ListItemIcon>
            <ListItemText primary="スケジュール" />
          </StyledListItemButton>
          <StyledListItemButton
            selected={path.startsWith("/guest/map")}
            onClick={() => navigate(`/guest/map?id=${guestId}&token=${guestHash}&type=${guestType}`)}
          >
            <ListItemIcon>
              <MapIcon />
            </ListItemIcon>
            <ListItemText primary="エリアマップ" />
          </StyledListItemButton>
        </List>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Version />
      </Box>
    </Drawer>
  );
};

export default GuestDrawerLeft;
