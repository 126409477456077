import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import {
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material/";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import QrCodeIcon from '@mui/icons-material/QrCode';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MapIcon from '@mui/icons-material/Map';


const GuestBottomNav: React.VFC = () => {
  const path = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const guestId = searchParams.get("id") || "";
  const guestToken = searchParams.get("token") || "";
  const guestType = searchParams.get("type") || "";
  const [pageBool, setPageBool] = React.useState<boolean>(false)
  const [value, setValue] = React.useState("other");
  const navigate = useNavigate();

  useEffect(() => {
    if (path.startsWith("/guest/qr")) {
      setValue(`/guest/qr?id=${guestId}&token=${guestToken}&type=${guestType}`);
    } else if (path.startsWith("/guest/schedule")) {
      setValue(`/guest/schedule?id=${guestId}&token=${guestToken}&type=${guestType}`);
    }else {
      setValue(`/guest?id=${guestId}&token=${guestToken}&type=${guestType}`);
    }
    if (guestId == "" || guestToken == "" || guestType == ""){
      setPageBool(true)
    }
  });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`${newValue}`);
  };

  return (
    <>
      {path.startsWith("/guest") && !pageBool && (
          <Paper
            // https://rishuntrading.co.jp/blog/programing/safari_z-index_notwork/
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 200,
              transform: "translateZ(1px)",
            }}
            elevation={3}
          >
          <BottomNavigation
            showLabels
            value={value}
            onChange={handleChange}
          >
            <BottomNavigationAction
              label="ホーム"
              value={`/guest?id=${guestId}&token=${guestToken}&type=${guestType}`}
              icon={<HomeRoundedIcon />}
            />
            <BottomNavigationAction
              label="QRコード"
              value={`/guest/qr?id=${guestId}&token=${guestToken}&type=${guestType}`}
              icon={<QrCodeIcon />}
              sx={{ whiteSpace: "nowrap" }}
            />
            <BottomNavigationAction
              label="スケジュール"
              value={`/guest/schedule?id=${guestId}&token=${guestToken}&type=${guestType}`}
              icon={<CalendarMonthIcon />}
              sx={{ whiteSpace: "nowrap" }}
            />
            <BottomNavigationAction
              label="エリアマップ"
              value={`/guest/map?id=${guestId}&token=${guestToken}&type=${guestType}`}
              icon={<MapIcon />}
              sx={{ whiteSpace: "nowrap" }}
            />
          </BottomNavigation>
          </Paper>
        )}
    </>
  );
};

export default GuestBottomNav;
